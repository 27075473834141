import { allAkzeptanzgruende, allUrsachen } from '@/core/flugverfuegbarkeit/bearbeitungs-options.config.js';
const moment = require('moment');
import { differenceInDays, parseISO } from 'date-fns';
import { fernRegions, euRegions } from '@/core/common/helpers/utils.js';
import { getRegionsmanagerOfReisekuerzel } from '@/core/common/helpers/utils.js';
import store from '@/core/common/services/vuex-store.js';
const reisekuerzelOptions = store.getters.getReisekuerzelOptions.map(r => r.reisekuerzel);

const dateFormatterStandard = params => {
  const abfragedatum = params.value;
  if (!abfragedatum) return '';
  return moment(abfragedatum, moment.ISO_8601).format('DD.MM.YYYY');
};
const dateFormatterWithHours = params => {
  const abfragedatum = params.value;
  if (!abfragedatum) return '';
  return moment(abfragedatum, moment.ISO_8601).format('DD.MM.YYYY HH:mm');
};
const fehlertypen = [
  'UNBEKANNT',
  'FLUG_MIT_HOTEL_NICHT_KOMBINIERBAR',
  'BIS_DATUM_FALSCH_ODER_FEHLT',
  'RUNDREISE_AUSGEBUCHT',
  'MINIMALE_AUFENTHALTSDAUER_UNTERSCHRITTEN',
  'DAS_ZIMMERKONTINGENT_IST_AUSGEBUCHT',
  'LEISTUNG_HOTEL_FUER_DIESEN_ZEITRAUM_NICHT_BUCHBAR',
  'LEISTUNG_FLUG_IST_AUSGEBUCHT',
  'LEISTUNG_FLUG_IST_NICHT_IM_VERANSTALTERANGEBOT',
  'ANREISETAG_NICHT_MOEGLICH',
  'ZIMMERBELEGUNG_IST_FALSCH',
  'NEBENLEISTUNG_IST_NICHT_BUCHBAR',
  'LEISTUNG_FLUG_DER_HINFLUG_IST_AUSGEBUCHT',
  'LEISTUNG_3_LETTER_CODE_IST_UNGUELTIG',
  'BITTE_KEINE_LUECKEN_DATUMSUEBERSCHNEIDUNGEN_BEI_DEN_HOTEL_BUCHUNGEN',
  'MINDESTAUFENTHALT_IST_UNTERSCHRITTEN',
  'LEISTUNG_FLUG_DER_RUECKFLUG_IST_AUSGEBUCHT',
  'KEIN_PREIS_VORHANDEN',
  'FLUG_KANN_ZUR_ZEIT_NICHT_GEBUCHT_WERDEN',
  'DAS_RESERVIERUNGSSYSTEM_IST_MOMENTAN_NICHT_VERFUEGBAR',
];
const garantiertFilterRenderer = params => {
  if (params.value === '(Select All)') {
    return params.value;
  } else if (params.value) {
    return "<i class='fas fa-check text-success'/>";
  } else if (!params.value) {
    return "<i class='ml-1 fas fa-times text-danger'/>";
  }
};

const bearbeitungsFarben = [
  {
    label: 'Akzeptiert',
    value: 'Akzeptiert',
  },
  {
    label: 'Verfügbarkeit geschaffen',
    value: 'VerfuegbarkeitGeschaffen',
  },
  {
    label: 'Verfügbarkeit nicht geschaffen',
    value: 'VerfuegbarkeitNichtGeschaffen',
  },
  {
    label: 'Nicht bearbeitet',
    value: 'null',
  },
];

export const columnDefsYieldOverview = [
  {
    field: 'farben',
    headerName: '',
    maxWidth: 40,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: bearbeitungsFarben.map(item => item.value),
      valueFormatter: params => bearbeitungsFarben.find(item => item.value === params.value)?.label,
      debounceMs: 1000,
      cellRenderer: 'FilterRendererFarben',
      comparator: (a, b) => {
        if (a === 'null') return 1;
        else if (a === b) return 0;
        return a > b ? 1 : -1;
      },
    },
    floatingFilter: true,
    resizable: false,
    pinned: 'left',
    cellRenderer: params => {
      if (params.data.bearbeitungStatus === 'Akzeptiert') {
        return '<div style="width: 6px; height:100%; background-color:#0088FF"></div>';
      } else if (params.data.bearbeitungStatus === 'VerfuegbarkeitGeschaffen') {
        return '<div style="width: 6px; height:100%; background-color:#1bc5bd"></div>';
      } else if (params.data.bearbeitungStatus === 'VerfuegbarkeitNichtGeschaffen') {
        return '<div style="width: 6px; height:100%; background-color:#F64E60"></div>';
      }
    },
  },
  {
    field: 'bearbeitung',
    headerName: '',
    pinned: 'left',
    resizable: false,
    sortable: false,
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: ['Automatisch bearbeitet', 'Manuell bearbeitet'],
      debounceMs: 1000,
    },
    width: 40,
    maxWidth: 40,
    cellRenderer: params => {
      // automatisch bearbeitet
      if (params.data.lastModifiedUserId === null && params.data.bearbeitungStatus !== null) {
        return '<span>🤖</span>';
      } else return '';
    },
  },
  {
    field: 'reisetermin.reise.reisekuerzel',
    headerCheckboxSelection: true,
    sortable: true,
    headerName: 'Reisekürzel',
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    checkboxSelection: true,
    width: 170,
    minWidth: 170,
    filterParams: {
      values: reisekuerzelOptions,
      debounceMs: 1000,
    },
    pinned: 'left',
  },
  {
    field: 'reisetermin.abreisedatum',
    sortable: false,
    headerName: 'Abreisedatum',
    valueFormatter: dateFormatterStandard,
    minWidth: 170,
    width: 170,
    floatingFilterComponent: 'AgDateRangePicker',
    floatingFilterComponentParams: {
      isChild: true,
    },
    filter: 'AgDateRangePicker',
    floatingFilter: true,
    pinned: 'left',
  },
  {
    field: 'reisetermin.hotelendUndRueckflugdatum',
    sortable: true,
    headerName: 'Rückflugdatum',
    valueFormatter: dateFormatterStandard,
    minWidth: 175,
    width: 175,
    floatingFilterComponent: 'AgDateRangePicker',
    floatingFilterComponentParams: {
      isChild: true,
    },
    filter: 'AgDateRangePicker',
    floatingFilter: true,
    pinned: 'left',
  },
  {
    field: 'deutscherAbflughafenIataCode',
    sortable: true,
    headerName: 'ABFH',
    width: 100,
    headerTooltip: 'Deutscher Abflughafen',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['BER', 'DUS', 'FRA', 'HAM', 'MUC', 'VIE', 'ZRH'],
      debounceMs: 1000,
    },
    floatingFilter: true,
    pinned: 'left',
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 100,
    sortable: false,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['NichtVerfuegbar', 'Verfuegbar<200', 'AufAnfrage', 'Verfuegbar>200'],
      debounceMs: 1000,
      cellRenderer: 'CellRendererStatus',
      cellRendererParams: { isFilterRenderer: true },
    },
    floatingFilterComponentParams: {
      isFilterParam: true,
    },
    pinned: 'left',
    cellRenderer: 'CellRendererStatus',
    minWidth: 100,
  },
  {
    field: 'reisetermin.reise.prioritaet',
    sortable: true,
    headerName: 'Priorität',
    filter: 'agSetColumnFilter',
    width: 120,
    minWidth: 120,
    filterParams: {
      values: [0, 1, 2, 3],
      debounceMs: 1000,
      cellRenderer: 'CellRendererPrioritaet',
    },
    floatingFilter: true,
    cellRenderer: 'CellRendererPrioritaet',
  },
  {
    field: 'aufpreis',
    sortable: true,
    headerName: 'Aufpreis',
    headerTooltip: 'Aufpreis = VK - Basispreis - EZZ - optionaler Flugaufpreis',
    cellRenderer: params => {
      if (params.value !== null) {
        if (params.value > 200) {
          return `<span class="badge badge-danger" style="font-size: 13px">${params.value}€</span>`;
        } else if (params.value <= 200) {
          return `<span class="badge badge-success" style="font-size: 13px">${params.value}€</span>`;
        }
      }
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      values: ['<0€', '0€', '0-100€', '100-200€', '>200€'],
      debounceMs: 1000,
      comparator: (a, b) => (a === '0€' ? 1 : a < b),
    },
    pinned: 'left',
    minWidth: 120,
    width: 120,
    floatingFilter: true,
  },
  {
    headerName: 'TS',
    field: 'reisetermin.metadata.transferszenario',
    headerTooltip: 'Transferszenario',
    minWidth: 80,
    width: 80,
    sortable: true,
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [
        'T1_1inkl',
        'T2_1inkl_exkl_mögl',
        'T3_unbegr_inkl',
        'T4_unbegr_exkl',
        'T5_fest_inkl',
        'T5_2',
        'T5_3',
        'T6',
        'TX_ohne',
      ],
      valueFormatter: params => params.value?.substring(0, 2),
      debounceMs: 1000,
      comparator: (a, b) => (a === '0€' ? 1 : a < b),
    },
    floatingFilter: true,
    cellRenderer: params => params.value?.substring(0, 2),
  },
  {
    field: 'abfragefehler.typ',
    sortable: true,
    headerName: 'Fehlermeldung',
    minWidth: 165,
    filter: 'agSetColumnFilter',
    floatingFilter: true,
    filterParams: {
      values: fehlertypen,
      valueFormatter: params => params.value.replaceAll('_', ' '),
      debounceMs: 1000,
    },
    tooltipField: 'abfragefehler.text',
    cellRenderer: params => {
      if (params.value) {
        const firstTenLetters = params.value.substring(0, 14).replaceAll('_', ' ');
        const cellValue = firstTenLetters + '...';
        return cellValue;
      }
    },
  },
  {
    field: 'flugstammHinflug',
    sortable: true,
    headerName: 'Flugstamm Hin',
    filter: 'agTextColumnFilter',
    minWidth: 170,
    filterParams: {
      buttons: ['apply'],
    },
  },
  {
    field: 'zielflughafenIataCode',
    sortable: false,
    headerName: 'Hin-Nach',
    headerTooltip: 'Zielland Zielflughafen',
    width: 105,
    minWidth: 105,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
  },
  {
    field: 'abflughafenIataCode',
    sortable: false,
    headerName: 'Rück-Von',
    width: 105,
    minWidth: 105,
    headerTooltip: 'Zielland Abflughafen',
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    filterParams: {
      buttons: ['apply'],
    },
  },
  {
    field: 'Pax',
    headerName: 'PAX',
    cellRenderer: 'CellRendererPax',
    minWidth: 200,
    width: 200,
    filter: false,
    suppressRowTransform: true,
    cellStyle: {
      'line-height': 'normal',
      display: 'flex',
      'align-items': 'center',
      'justify-items': 'center',
      overflow: 'visible',
    },
  },
  {
    field: 'reisetermin.garantiert',
    sortable: true,
    headerName: 'Garantiert',

    cellRenderer: params => {
      const { value } = params;
      if (value) {
        return "<i class='fas fa-check text-success'/>";
      } else {
        return "<i class='ml-1 fas fa-times text-danger'/>";
      }
    },
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [true, false],
      cellRenderer: garantiertFilterRenderer,
      debounceMs: 1000,
    },
    minWidth: 130,
    width: 130,
  },
  {
    field: 'reisetermin.releasedatum',
    sortable: true,
    headerName: 'Tage bis Release',
    minWidth: 180,
    width: 180,
    cellRenderer: params => {
      const daysUntilRelease = differenceInDays(parseISO(params.value), new Date());
      return daysUntilRelease;
    },
    floatingFilter: false,
  },
  {
    field: 'zimmertyp',
    sortable: false,
    minWidth: 120,
    width: 120,
    headerName: 'Zimmertyp',
    filter: 'agSetColumnFilter',
    filterParams: {
      values: [
        'AA',
        'AAM',
        'AP',
        'APM',
        'ASM',
        'BAG',
        'BAM',
        'BAP',
        'BAS',
        'BBA',
        'BBP',
        'BFA',
        'BFP',
        'BPA',
        'BU',
        'BUA',
        'BUB',
        'BUG',
        'BUM',
        'BUP',
        'BUS',
        'BZA',
        'DA',
        'DAB',
        'DAC',
        'DAE',
        'DAF',
        'DAG',
        'DAH',
        'DAK',
        'DAL',
        'DAM',
        'DAP',
        'DAR',
        'DAS',
        'DB',
        'DBF',
        'DBS',
        'DBV',
        'DBW',
        'DCG',
        'DDA',
        'DDG',
        'DDH',
        'DDM',
        'DEX',
        'DF',
        'DG',
        'DGA',
        'DGH',
        'DKL',
        'DLA',
        'DLH',
        'DM',
        'DMS',
        'DP',
        'DS',
        'DSM',
        'DSV',
        'DT',
        'DTZ',
        'DVB',
        'DVS',
        'DZ',
        'DZB',
        'DZC',
        'DZD',
        'DZE',
        'DZF',
        'DZG',
        'DZH',
        'DZK',
        'DZM',
        'DZP',
        'DZR',
        'DZS',
        'DZT',
        'DZU',
        'DZZ',
        'EB',
        'EBF',
        'EBW',
        'ECG',
        'EDG',
        'EDM',
        'EG',
        'EGA',
        'EP',
        'ES',
        'ESM',
        'ESV',
        'ET',
        'EXM',
        'EZ',
        'EZA',
        'EZB',
        'EZD',
        'EZE',
        'EZF',
        'EZG',
        'EZH',
        'EZK',
        'EZM',
        'EZP',
        'EZR',
        'EZS',
        'EZT',
        'EZU',
        'EZX',
        'HAM',
        'HAP',
        'HB',
        'HBF',
        'HBG',
        'HBM',
        'HBU',
        'HBV',
        'HCG',
        'HD',
        'HDA',
        'HDB',
        'HDD',
        'HDE',
        'HDG',
        'HDH',
        'HDI',
        'HDK',
        'HDM',
        'HDP',
        'HDR',
        'HDS',
        'HDT',
        'HGD',
        'HJM',
        'HJU',
        'HKL',
        'HLS',
        'HMD',
        'HP',
        'HPS',
        'HRS',
        'HSD',
        'HSM',
        'HST',
        'HSU',
        'HSV',
        'HTR',
        'HTW',
        'HVC',
        'HVI',
        'JAM',
        'JAS',
        'JU',
        'JUA',
        'JUG',
        'JUM',
        'JUS',
        'LS',
        'MZ',
        'MZG',
        'PS',
        'PSA',
        'RS',
        'RSA',
        'SA',
        'SAB',
        'SAM',
        'SAS',
        'SMB',
        'SSF',
        'SSM',
        'ST',
        'STA',
        'STB',
        'STD',
        'STE',
        'STM',
        'STR',
        'SU',
        'SUA',
        'SUE',
        'SUM',
        'TDZ',
        'TEZ',
        'THD',
        'TSM',
        'TW',
        'TWA',
        'TWD',
        'TWM',
        'TWS',
        'VCA',
        'VDA',
        'VI',
        'VIA',
        'VIC',
        'VIE',
        'VIL',
        'VW',
        'ZDD',
        'ZED',
        'WAL',
        'MMR',
        'CH_ast',
        'ECO',
        'VEO',
        'HVB',
        'RL',
        'FZ',
        'HGV',
        'GVA',
        'FAK',
        'DZA',
        'CHI',
        'HCO',
        'WA_ast',
        'GOR',
        'GVI',
        'MW',
        'DCO',
        'GO_ast',
      ],
      debounceMs: 1000,
    },
    floatingFilter: true,
  },
  {
    field: 'regionsmanager',
    headerName: 'RM',
    headerTooltip: 'Regionsmanager',
    filter: 'agSetColumnFilter',
    filterParams: { values: ['NIGO', 'LEBE', 'STGE', 'SYKE', 'NIEX', 'SAKI'], debounceMs: 1000 },
    minWidth: 150,
    floatingFilter: true,
    cellRenderer: params => {
      const { reisekuerzel } = params.data.reisetermin.reise;
      const responsibleManagers = getRegionsmanagerOfReisekuerzel(reisekuerzel);
      return responsibleManagers;
    },
  },
  {
    field: 'abfragedatum',
    headerName: 'Abfragedatum',
    valueFormatter: dateFormatterWithHours,
    floatingFilterComponent: 'AgDateRangePicker',
    floatingFilterComponentParams: {
      isChild: true,
    },
    filter: 'AgDateRangePicker',
    floatingFilter: true,
    minWidth: 180,
    width: 180,
  },
  {
    field: 'akzeptanzgrund',
    headerName: 'Akzeptanzgrund',
    filter: 'agSetColumnFilter',
    valueFormatter: params =>
      allAkzeptanzgruende.find(akzeptanzgrund => akzeptanzgrund.enum === params.value)?.label,
    filterParams: {
      values: allAkzeptanzgruende.map(akzeptanzgrund => akzeptanzgrund.enum),
      debounceMs: 1000,
      valueFormatter: params =>
        allAkzeptanzgruende.find(akzeptanzgrund => akzeptanzgrund.enum === params.value)?.label,
    },
    floatingFilter: true,
    minWidth: 180,
    width: 180,
  },
  {
    field: 'ursache',
    headerName: 'Ursache',
    filter: 'agSetColumnFilter',
    valueFormatter: params => allUrsachen.find(ursache => ursache.enum === params.value)?.label,
    filterParams: {
      values: allUrsachen.map(ursache => ursache.enum),
      debounceMs: 1000,
      valueFormatter: params => allUrsachen.find(ursache => ursache.enum === params.value)?.label,
    },
    floatingFilter: true,
    minWidth: 180,
    width: 180,
  },
  {
    field: 'aufgaben',
    headerName: 'Aufgaben',
    cellRenderer: 'CellRendererAufgaben',
    floatingFilter: false,
    cellRendererParams: { filter: 'fvc', ansicht: 'fvc-overview' },
    minWidth: 500,
    width: 500,
    cellStyle: {
      'line-height': 'normal',
      display: 'flex',
      'align-items': 'center',
    },
  },
  {
    field: 'reisetermin.reise.region',
    headerName: 'Region',
    filter: 'agSetColumnFilter',
    width: 100,
    minWidth: 100,
    filterParams: {
      values: ['EU', 'Fern'],
      debounceMs: 1000,
    },
    cellRenderer: params => {
      if (euRegions.includes(params.value)) {
        return 'EU';
      } else if (fernRegions.includes(params.value)) {
        return 'Fern';
      } else return '-';
    },
    hide: true,
  },
  {
    field: 'id',
    hide: true,
    filter: 'agTextColumnFilter',
  },
];
