<template>
  <FilterOverview :showFilterInitially="false" :title="title" @reload="onReload">
    <template #toolbar-left>
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <slot name="my-overview"></slot>
        <b-form-checkbox
          v-model="lastAbfrageCheckbox"
          class="mr-5"
          @change="gridApi.onFilterChanged()"
          switch
        >
          Letzte Abfrage
        </b-form-checkbox>
        <b-form-checkbox
          class="mr-5"
          v-model="regionsmanagerAnsichtCheckbox"
          @change="regionsmanagerAnsichtChanged"
          switch
        >
          Meine Reisen
        </b-form-checkbox>
        <b-button-group size="sm" style="width: 100px">
          <b-button
            v-for="regionFilter in regionFilters"
            :key="regionFilter"
            :id="regionFilter"
            @click="regionFilterClicked"
            :disabled="regionFilterDisabled"
            :variant="regionFilter === regionFilterSelected ? 'primary' : ''"
            class="mr-1"
            style="width: 50%"
          >
            {{ regionFilter }}
          </b-button>
        </b-button-group>
        <b-button size="sm ml-5" variant="danger" v-if="isFilterSupplied" @click="resetAllFilters">
          Alle Filter zurücksetzen
        </b-button>
      </div>
    </template>
    <template #toolbar-right>
      <div>
        <b-button
          variant="success"
          size="sm"
          class="mr-3"
          @click="onButtonExport"
          v-if="allSelectedRows.length > 1"
        >
          Markierte ABFHT exportieren
        </b-button>
        <b-button variant="primary" size="sm" v-b-modal.multipleEditModal v-if="allSelectedRows.length > 1">
          Markierte ABFHT bearbeiten
        </b-button>
        <MultipleEditModalFVC
          @bulkUpdateSuccess="successfulBulkUpdate"
          :ids="allSelectedRows.map(row => row.data.id).flat()"
          :stati="allSelectedRows.map(row => row.data.status).flat()"
          @openAufgabeErstellenModal="openAufgabeErstellenModal"
        ></MultipleEditModalFVC>
      </div>
    </template>

    <template #table="{ tableHeight }">
      <AgGridVue
        :style="{ height: tableHeight + 80 + 'px' }"
        class="ag-theme-alpine m-0 p-0"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowModelType="rowModelType"
        :sideBar="sideBar"
        :getRowHeight="getRowHeight"
        :statusBar="statusBar"
        suppressRowTransform
        :rowSelection="rowSelection"
        :rowMultiSelectWithClick="rowMultiSelectWithClick"
        :tooltipShowDelay="tooltipShowDelay"
        :tooltipMouseTrack="true"
        @grid-ready="onGridReady"
        @rowDoubleClicked="collapseSidebarAndLoadData"
        @rowSelected="onRowSelected"
      ></AgGridVue>
      <AufgabeErstellenModal
        @aufgabeErstellt="onAufgabeErstelltFromFVC"
        :prefixedVerlinkungen="prefixedVerlinkungen"
        :disableAttachments="true"
        :fromFVC="true"
      ></AufgabeErstellenModal>
      <SidebarDetails
        :lastAbfrage="lastAbfrage"
        @rowSelectionChanged="changeRowSelection"
        @openAufgabeErstellenModal="openAufgabeErstellenModal"
        @dataChangeSuccessful="updateChangedRow"
        @hidden="resetPrefixedVerlinkungen"
      ></SidebarDetails>
    </template>
  </FilterOverview>
</template>
<script>
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import 'ag-grid-enterprise';
import * as applicationInsights from '@/core/common/services/application-insights.service';
import AufgabeErstellenModal from '@/components/aufgaben/aufgabe-erstellen-modal.vue';
import { add } from 'date-fns';
import AgDateRangePicker from '@/components/flugverfuegbarkeit/ag-date-range-picker.vue';
import FilterOverview from '@/components/common/filter-overview.vue';
import CellRendererPrioritaet from '@/components/flugverfuegbarkeit/cell-renderer-prioritaet.vue';
import CellRendererStatus from '@/components/flugverfuegbarkeit/cell-renderer-status.vue';
import FilterRendererFarben from '@/components/flugverfuegbarkeit/filter-renderer-farben.vue';
import StatusBarComponent from '@/components/flugverfuegbarkeit/status-bar-ag-grid.vue';
import {
  RESET_EDITED_FILTER,
  SET_EDITED_FILTER,
  GET_SINGLE_FLUGVERFUEGBARKEIT,
  SET_DETAILDATA,
  SET_LAST_ABFRAGEDATE,
  RESET_LAST_ABFRAGEDATE,
  GET_FLUGVERFUEGBARKEIT,
  SET_FLUGVERFUEGBARKEIT_SKIP,
  GET_FLUGPLANUNGSDATA,
  SET_FLUGVERFUEGBARKEIT_ORDER_BY,
  GET_LAST_ABFRAGE,
  GET_INFO_DATA,
} from '@/core/flugverfuegbarkeit/stores/flugverfuegbarkeit.module';

import { mapState, mapGetters } from 'vuex';
import { calculateLastSaturday, getRegionsmanagerById } from '@/core/common/helpers/utils';
import { columnDefsYieldOverview } from '@/core/flugverfuegbarkeit/yield-table-config.js';
import CustomHeader from '@/components/flugverfuegbarkeit/custom-header.vue';
import SidebarDetails from '@/components/flugverfuegbarkeit/sidebar-details.vue';
import CustomTooltip from '@/components/flugverfuegbarkeit/custom-tooltip.vue';
import AufgabenKategorienTooltip from '@/components/flugverfuegbarkeit/aufgaben-kategorien-tooltip.vue';
import CellRendererPax from '@/components/flugverfuegbarkeit/cell-renderer-pax.vue';
import regionsmanagerConfig from '@/core/flugverfuegbarkeit/regionsmanager-reisekuerzel.json';
import MultipleEditModalFVC from '@/components/flugverfuegbarkeit/multiple-edit-modal.vue';
import { utils, writeFileXLSX } from 'xlsx';
import CellRendererAufgaben from '@/components/produkte/reisetermine/cell-renderer-aufgaben.vue';

export default {
  name: 'App',
  components: {
    AgGridVue,
    FilterOverview,
    AgDateRangePicker,
    StatusBarComponent,
    AufgabeErstellenModal,
    SidebarDetails,
    CellRendererPax,
    FilterRendererFarben,
    CellRendererStatus,
    agColumnHeader: CustomHeader,
    CellRendererPrioritaet,
    CustomTooltip,
    AufgabenKategorienTooltip,
    MultipleEditModalFVC,
    CellRendererAufgaben,
  },
  props: {
    title: { type: String, default: 'FVC-Übersicht' },
    showingMyOverview: { type: Boolean, default: false },
  },
  data() {
    return {
      isFilterSupplied: false,
      isManuallyEditedFilter: false,
      isAutomaticallyEditedFilter: false,
      lastAbfrageCheckbox: true,
      regionsmanagerAnsichtCheckbox: false,
      columnDefs: null,
      statusBar: null,
      gridApi: null,
      columnApi: null,
      prefixedVerlinkungen: [],
      REGION_MANAGERS: regionsmanagerConfig,
      defaultColDef: {
        floatingFilter: true,
        filter: true,
        flex: 1,
        resizable: false,
        sortable: false,
        suppressMenu: true,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
        },
        filterParams: {
          defaultToNothingSelected: true,
        },
        tooltipComponent: 'CustomTooltip',
      },
      tooltipShowDelay: 0,
      sideBar: null,
      rowModelType: null,
      rowSelection: null,
      selectedRowID: null,
      rowMultiSelectWithClick: null,
      allSelectedRows: [],
      regionFilters: ['EU', 'Fern'],
      regionFilterSelected: null,
      regionFilterDisabled: true,
    };
  },
  created() {
    this.columnDefs = columnDefsYieldOverview;
    this.rowModelType = 'serverSide';
    this.sideBar = 'filters';
    this.statusBar = {
      statusPanels: [{ statusPanel: 'StatusBarComponent', key: 'statusBarCompKey', align: 'left' }],
    };
    this.rowSelection = 'multiple';
    this.rowMultiSelectWithClick = true;
  },
  computed: {
    ...mapState({
      lastAbfrage: state => state.flugverfuegbarkeit.lastAbfrage,
      flugverfuegbarkeit: state => state.flugverfuegbarkeit.flugverfuegbarkeit,
      count: state => state.flugverfuegbarkeit.count,
    }),
    ...mapGetters(['currentUserName', 'parsedRoles', 'currentUserId']),
  },
  methods: {
    onReload() {
      this.gridApi.onFilterChanged();
    },
    getRowHeight(params) {
      return params.data ? Math.max(1, params.data.aufgaben?.length) * 45 : 45;
    },
    onAufgabeErstelltFromFVC(type) {
      if (type === 'single') {
        this.collapseSidebar();
      } else if (type === 'bulk') {
        this.$bvModal.hide('multipleEditModal');
      }
      this.gridApi?.deselectAll();
      this.gridApi.onFilterChanged();
    },
    resetPrefixedVerlinkungen() {
      this.prefixedVerlinkungen = [];
    },
    openAufgabeErstellenModal(verlinkung) {
      this.prefixedVerlinkungen = verlinkung;
    },
    resetAllFilters() {
      this.gridApi.setFilterModel(null);
      let filterDate1 = this.gridApi.getFilterInstance('reisetermin.abreisedatum');
      let filterDate2 = this.gridApi.getFilterInstance('reisetermin.hotelendUndRueckflugdatum');
      let filterDate3 = this.gridApi.getFilterInstance('abfragedatum');
      filterDate1.onDateRangeFilterChanged(null);
      filterDate2.onDateRangeFilterChanged(null);
      filterDate3.onDateRangeFilterChanged(null);
      this.regionFilterSelected = null;
    },
    onButtonExport() {
      const exportData = [];
      this.gridApi.forEachNode((rowNode, i) => {
        if (i === 0) exportData.push(Object.keys(rowNode.data));
        if (rowNode.selected) {
          exportData.push(Object.values(rowNode.data));
        }
      });
      console.log(exportData);
      // Create a new workbook
      let wb = utils.book_new();

      // Convert the array to a worksheet
      let ws = utils.aoa_to_sheet(exportData);

      // Add the worksheet to the workbook
      utils.book_append_sheet(wb, ws, 'FVC_Export');

      // Write the workbook to a binary string and initiate download
      writeFileXLSX(wb, `FVC_Export.xlsx`);
    },

    checkIfFilterSupplied() {
      this.isFilterSupplied = this.gridApi && Object.keys(this.gridApi?.getFilterModel()).length > 0;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setSideBarVisible(false);
      if (this.$route.query.id) {
        const idFilter = this.gridApi?.getFilterInstance('id');
        if (!isNaN(this.$route.query.id))
          idFilter.setModel({ filterType: 'text', filter: this.$route.query.id });
        this.gridApi?.onFilterChanged();
      } else {
        if (this.$route.query.reise) {
          const reiseFilter = this.gridApi?.getFilterInstance('reisetermin.reise.reisekuerzel');
          reiseFilter.setModel({ filterType: 'text', type: 'startsWith', filter: this.$route.query.reise });
          this.gridApi?.onFilterChanged();
        }
        if (this.$route.query.abreisedatum) {
          const abreisedatumFilter = this.gridApi?.getFilterInstance('reisetermin.abreisedatum');
          abreisedatumFilter.setModel({
            dateFrom: this.$route.query.abreisedatum,
            dateTo: add(new Date(this.$route.query.abreisedatum), { days: 1 }),
            filterType: 'date',
            type: 'inRange',
          });
          this.gridApi?.onFilterChanged();
        }
        if (this.$route.query.deutscherAbflughafen) {
          const deutscherAbflughafen = this.gridApi?.getFilterInstance('deutscherAbflughafenIataCode');
          deutscherAbflughafen.setModel({
            values: [this.$route.query.deutscherAbflughafen],
            filter: 'set',
          });
          this.gridApi?.onFilterChanged();
        }
      }
      const updateData = () => {
        const server = this.server();
        const datasource = this.createServerSideDatasource(server);
        // register the datasource with the grid
        params.api.setServerSideDatasource(datasource);
      };

      updateData();
    },
    createServerSideDatasource(server) {
      return {
        getRows: async params => {
          console.log('[Datasource] - rows requested by grid: ', params.request);
          const response = await server.getData(params.request);
          if (response.success) {
            // supply rows for requested block to grid
            params.success({ rowData: response.rows });
            this.updateStatusBar(this.gridApi?.getDisplayedRowCount());
            if (this.$route.query.id && !isNaN(this.$route.query.id)) {
              this.selectedRowID = 0;
              let data;
              this.gridApi.forEachNode(rowNode => {
                if (rowNode.rowIndex === 0) {
                  rowNode.setSelected(true);
                  data = rowNode.data;
                }
              });
              this.queryLastAbfrage(data);
              this.$store.commit('flugverfuegbarkeit/' + SET_DETAILDATA, data);
              this.$store.dispatch(
                'flugverfuegbarkeit/' + GET_FLUGPLANUNGSDATA,
                data.reisetermin.reiseterminkuerzel
              );
              this.$store.dispatch('flugverfuegbarkeit/' + GET_INFO_DATA, data.id);
              this.collapseSidebar();
            }
          } else {
            params.fail();
          }
        },
      };
    },
    server() {
      return {
        getData: async request => {
          this.setFilters(request);

          const usedFilters = Object.keys(request.filterModel);
          if (usedFilters.length > 0) {
            usedFilters.forEach(filterName => {
              applicationInsights.trackEventWithRouteAndUser(
                { name: `Applied FVC Overview filter ${filterName}` },
                {
                  roles: this.parsedRoles,
                  currentUserName: this.currentUserName,
                }
              );
            });
          }

          const response = await this.$store.dispatch(
            'flugverfuegbarkeit/' + GET_FLUGVERFUEGBARKEIT,
            request
          );
          this.regionFilterDisabled = false;
          return {
            success: true,
            rows: response,
          };
        },
      };
    },
    setFilters(request) {
      this.applyEditFilters();
      this.gridApi?.deselectAll();
      this.checkIfFilterSupplied();

      // Apply checkbox
      if (this.lastAbfrageCheckbox) {
        const lastSaturdayDate = calculateLastSaturday();
        this.$store.commit('flugverfuegbarkeit/' + SET_LAST_ABFRAGEDATE, lastSaturdayDate);
      } else {
        this.$store.commit('flugverfuegbarkeit/' + RESET_LAST_ABFRAGEDATE);
      }
      // Apply sorting
      if (request.sortModel.length > 0) {
        // Compose sort string and replace dots with slashes for query
        const sortValues = [
          { ...request.sortModel[0], sortIndex: 0 },
          { colId: 'reisetermin.abreisedatum', sort: 'asc', sortIndex: 1 },
          { colId: 'abfragedatum', sort: 'desc', sortIndex: 2 },
        ];
        const sortString = sortValues
          .map(sorter => (sorter.colId + ' ' + sorter.sort).replaceAll('.', '/'))
          .join(', ');
        this.$store.commit('flugverfuegbarkeit/' + SET_FLUGVERFUEGBARKEIT_ORDER_BY, sortString);
        this.$store.commit('flugverfuegbarkeit/' + SET_FLUGVERFUEGBARKEIT_SKIP, request.startRow);
      }
      // Reset sorting
      else {
        const sortValues = [
          { colId: 'reisetermin.abreisedatum', sort: 'asc', sortIndex: 0 },
          { colId: 'abfragedatum', sort: 'desc', sortIndex: 1 },
        ];
        const sortString = sortValues
          .map(sorter => (sorter.colId + ' ' + sorter.sort).replaceAll('.', '/'))
          .join(', ');
        this.$store.commit('flugverfuegbarkeit/' + SET_FLUGVERFUEGBARKEIT_ORDER_BY, sortString);
        this.$store.commit('flugverfuegbarkeit/' + SET_FLUGVERFUEGBARKEIT_SKIP, request.startRow);
      }
    },
    updateStatusBar(displayedRowsCount) {
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setRowCount(displayedRowsCount);
      statusBarComponent?.setOdataCount(this.count);
    },
    collapseSidebarAndLoadData($event) {
      this.gridApi?.deselectAll();
      const { data, rowIndex } = $event;
      console.log(data, rowIndex);
      this.selectedRowID = rowIndex;
      this.gridApi.forEachNode(rowNode => {
        if (rowNode.rowIndex === rowIndex) {
          rowNode.setSelected(true);
        }
      });
      this.queryLastAbfrage(data);
      this.$store.commit('flugverfuegbarkeit/' + SET_DETAILDATA, data);
      this.$store.dispatch('flugverfuegbarkeit/' + GET_FLUGPLANUNGSDATA, data.reisetermin.reiseterminkuerzel);
      this.$store.dispatch('flugverfuegbarkeit/' + GET_INFO_DATA, data.id);
      this.collapseSidebar();
    },
    collapseSidebar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-backdrop');
    },
    async queryLastAbfrage(data) {
      const {
        reisetermin,
        abfragedatum,
        deutscherAbflughafenIataCode,
        zielflughafenIataCode,
        abflughafenIataCode,
        zimmertyp,
        verpflegung,
      } = data;
      const { reiseterminkuerzel } = reisetermin;
      const formattedAbfragedatum = abfragedatum.substring(0, 10);
      const filter = {
        reiseterminkuerzel,
        deutscherAbflughafenIataCode,
        formattedAbfragedatum,
        zielflughafenIataCode,
        abflughafenIataCode,
        zimmertyp,
        verpflegung,
      };

      this.$store.dispatch('flugverfuegbarkeit/' + GET_LAST_ABFRAGE, filter);
    },
    async updateChangedRow(id) {
      console.log(id, ' id from update changed row');
      const updatedRow = await this.$store.dispatch(
        'flugverfuegbarkeit/' + GET_SINGLE_FLUGVERFUEGBARKEIT,
        id
      );
      this.$store.commit('flugverfuegbarkeit/' + SET_DETAILDATA, updatedRow);

      this.gridApi.forEachNode(rowNode => {
        if (rowNode.data.id === id) {
          rowNode.updateData(updatedRow);
        }
      });
      const params = {
        force: true,
        suppressFlash: false,
      };
      this.gridApi.refreshCells(params);
    },
    async changeRowSelection(direction) {
      const displayedRows = this.gridApi?.getDisplayedRowCount();
      if (direction === 'up') {
        if (this.selectedRowID !== 0) {
          const targetedRowID = this.selectedRowID - 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.$store.dispatch(
                'flugverfuegbarkeit/' + GET_FLUGPLANUNGSDATA,
                rowNode.data.reisetermin.reiseterminkuerzel
              );

              this.queryLastAbfrage(rowNode.data);
              this.$store.commit('flugverfuegbarkeit/' + SET_DETAILDATA, rowNode.data);
              this.$store.dispatch('flugverfuegbarkeit/' + GET_INFO_DATA, rowNode.data.id);
              this.selectedRowID = targetedRowID;
            }
          });
        }
      } else if (direction === 'down') {
        if (this.selectedRowID + 1 < displayedRows) {
          const targetedRowID = this.selectedRowID + 1;
          this.gridApi.forEachNode(rowNode => {
            if (rowNode.rowIndex === targetedRowID) {
              rowNode.setSelected(true, true);
              this.gridApi.ensureIndexVisible(rowNode.rowIndex);
              this.$store.dispatch(
                'flugverfuegbarkeit/' + GET_FLUGPLANUNGSDATA,
                rowNode.data.reisetermin.reiseterminkuerzel
              );

              this.queryLastAbfrage(rowNode.data);
              this.$store.commit('flugverfuegbarkeit/' + SET_DETAILDATA, rowNode.data);
              this.$store.dispatch('flugverfuegbarkeit/' + GET_INFO_DATA, rowNode.data.id);
              this.selectedRowID = targetedRowID;
            }
          });
        }
      }
    },
    queryForUser() {
      const targetUser = getRegionsmanagerById(this.currentUserId);
      // Wenn es den Nutzer in der Config gibt
      if (targetUser) {
        const regionsmanagerFilter = this.gridApi?.getFilterInstance('regionsmanager');
        regionsmanagerFilter.setModel({
          filterType: 'set',
          values: [targetUser.shortname],
        });
      }
      this.gridApi.onFilterChanged();
    },
    onRowSelected($event) {
      this.allSelectedRows = this.getAllSelectedRows();
      const statusBarComponent = this.gridApi?.getStatusPanel('statusBarCompKey');
      statusBarComponent?.setMarkedCount(this.allSelectedRows.length);
    },
    getAllSelectedRows() {
      const rowsSelected = [];
      this.gridApi.forEachNode((node, index) => {
        if (node.selected) {
          rowsSelected.push(node);
        }
      });
      return rowsSelected;
    },
    successfulBulkUpdate() {
      setTimeout(() => this.gridApi.onFilterChanged(), 1000);
    },
    clickManualEditFilterButton() {
      this.isManuallyEditedFilter = !this.isManuallyEditedFilter;
      setTimeout(() => this.gridApi.onFilterChanged(), 100);
    },
    clickAutomaticEditFilterButton() {
      this.isAutomaticallyEditedFilter = !this.isAutomaticallyEditedFilter;
      setTimeout(() => this.gridApi.onFilterChanged(), 100);
    },
    applyEditFilters() {
      // Apply edit filters from subheader
      const filterState = [
        {
          state: this.isManuallyEditedFilter,
          odataFilter: '(lastModifiedUserId ne null and bearbeitungStatus ne null)',
        },
        {
          state: this.isAutomaticallyEditedFilter,
          odataFilter:
            '(lastModifiedUserId eq null and bearbeitungDataSetByCore eq true and bearbeitungStatus ne null)',
        },
      ];

      const editedFilterOdataString =
        '(' +
        filterState
          .filter(filter => filter.state)
          .map(filter => filter.odataFilter)
          .flat()
          .join(' or ') +
        ')';
      if (!this.isAutomaticallyEditedFilter && !this.isManuallyEditedFilter) {
        this.$store.commit('flugverfuegbarkeit/' + RESET_EDITED_FILTER);
      } else {
        this.$store.commit('flugverfuegbarkeit/' + SET_EDITED_FILTER, editedFilterOdataString);
      }
    },
    regionsmanagerAnsichtChanged() {
      if (this.regionsmanagerAnsichtCheckbox) {
        // disable regionsmanager floating filter
        this.columnDefs = this.columnDefs.map(col => {
          if (col.field === 'regionsmanager') {
            return { ...col, floatingFilter: false };
          }
          return col;
        });
        // set regionsmanager filter model
        this.queryForUser();
      } else {
        // enable regionsmanager floating filter
        this.columnDefs = this.columnDefs.map(col => {
          if (col.field === 'regionsmanager') {
            return { ...col, floatingFilter: true };
          }
          const regionsmanagerFilter = this.gridApi?.getFilterInstance('regionsmanager');
          regionsmanagerFilter.setModel({});
          return col;
        });
        // reset regionsmanager filter model
        const regionsmanagerFilter = this.gridApi?.getFilterInstance('regionsmanager');
        regionsmanagerFilter.setModel({});
        this.gridApi.onFilterChanged();
      }
    },
    regionFilterClicked($event) {
      const id = $event.target.id;
      if (this.regionFilterSelected === null || this.regionFilterSelected !== id) {
        this.regionFilterSelected = id;
      } else if (this.regionFilterSelected === id) {
        this.regionFilterSelected = null;
      }

      // apply ag grid filter
      if (this.regionFilterSelected) {
        const regionsmanagerFilter = this.gridApi?.getFilterInstance('reisetermin.reise.region');
        regionsmanagerFilter.setModel({ values: [this.regionFilterSelected] });
        this.gridApi.onFilterChanged();
      } else {
        const regionsmanagerFilter = this.gridApi?.getFilterInstance('reisetermin.reise.region');
        regionsmanagerFilter.setModel({});
        this.gridApi.onFilterChanged();
      }
    },
  },
};
</script>
<style scoped>
:deep(.ag-theme-alpine) {
  font-family: 'Poppins';
}
:deep(.ag-cell[col-id='farben']) {
  padding-left: 0px !important;
  border: none;
}
:deep(.ag-cell[col-id='bearbeitung']) {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: none;
}
:deep(.ag-header-cell[aria-colindex='1']),
:deep(.ag-header-cell[aria-colindex='2']) {
  padding-left: 4px;
  padding-right: 4px;
}
:deep(.ag-theme-alpine .ag-header-cell) {
  font-weight: 500;
  font-size: 14px;
}
</style>
