<template>
  <b-modal
    id="multipleEditModal"
    :title="ids.length + ' ABFHT bearbeiten'"
    size="lg"
    @hidden="resetFormVariables"
    @shown="onShow"
  >
    <b-overlay :show="isBusy">
      <!-- Ursachen -->
      <b-list-group horizontal
        ><b-list-group-item
          class="col-6 d-flex align-items-center"
          style="
            background-color: rgba(114, 114, 114, 0.05);
            border-style: solid;
            border-width: 1px;
            border-color: rgba(114, 114, 114, 0.15);
          "
        >
          <h6 class="m-0 my-1">Ursachen</h6>
        </b-list-group-item>
        <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2">
          <b-dropdown
            class="dropdown"
            :text="formVariables.ursacheSelected?.label || 'Ursache auswählen'"
            size="sm"
          >
            <b-dropdown-item
              v-for="item in ursachenOptions"
              @click="changeUrsache(item)"
              :active="item.label === formVariables.ursacheSelected?.label"
              :key="item.label"
            >
              <span>{{ item.label }} </span>
            </b-dropdown-item> </b-dropdown
          ><i
            v-if="formVariables.ursacheSelected"
            class="fas fa-times text-danger ml-auto"
            @click="resetUrsache"
            style="cursor: pointer"
          ></i>
        </b-list-group-item>
      </b-list-group>
      <!-- Beschreibung -->
      <b-list-group horizontal
        ><b-list-group-item
          class="col-6 d-flex align-items-center"
          style="
            background-color: rgba(114, 114, 114, 0.05);
            border-style: solid;
            border-width: 1px;
            border-color: rgba(114, 114, 114, 0.15);
          "
        >
          <h6 class="m-0 my-1">Beschreibung</h6>
        </b-list-group-item>
        <b-list-group-item class="col-6 d-flex align-items-center p-0">
          <b-form-input
            v-model="formVariables.beschreibungText"
            class="border-0"
            style="font-size: 13px"
            placeholder="Beschreibung einfügen..."
          ></b-form-input
          ><i
            v-if="formVariables.beschreibungText"
            class="fas fa-times text-danger ml-auto mr-5"
            @click="resetBeschreibung"
            style="cursor: pointer"
          ></i>
        </b-list-group-item>
      </b-list-group>
      <b-list-group horizontal
        ><b-list-group-item
          class="col-6 d-flex align-items-center"
          style="
            background-color: rgba(114, 114, 114, 0.05);
            border-style: solid;
            border-width: 1px;
            border-color: rgba(114, 114, 114, 0.15);
          "
        >
          <h6 class="m-0 my-1">Status</h6>
        </b-list-group-item>
        <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2">
          <b-dropdown
            class="dropdown"
            :text="formVariables.statusSelected?.label || 'Status auswählen'"
            size="sm"
          >
            <b-dropdown-item
              v-for="item in statusOptions"
              @click="changeStatus(item)"
              :active="item?.label === formVariables.statusSelected?.label"
              :key="item?.label"
            >
              <span>{{ item?.label }} </span>
            </b-dropdown-item> </b-dropdown
          ><i
            v-if="formVariables.statusSelected"
            class="fas fa-times text-danger ml-auto"
            @click="resetBearbeitungstatus"
            style="cursor: pointer"
          ></i>
        </b-list-group-item>
      </b-list-group>
      <!-- Lösungsansatz -->
      <b-list-group horizontal v-if="formVariables.statusSelected?.enum === 'VerfuegbarkeitGeschaffen'"
        ><b-list-group-item
          class="col-6 d-flex align-items-center"
          style="
            background-color: rgba(114, 114, 114, 0.05);
            border-style: solid;
            border-width: 1px;
            border-color: rgba(114, 114, 114, 0.15);
          "
        >
          <h6 class="m-0 my-1">Lösungsansatz</h6>
        </b-list-group-item>
        <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2">
          <b-dropdown
            class="dropdown"
            :text="formVariables.loesungsansatzSelected?.label || 'Lösungsansatz auswählen'"
            size="sm"
          >
            <b-dropdown-item
              v-for="item in loesungsansatzOptions"
              @click="changeLoesungsansatz(item)"
              :active="item.label === formVariables.loesungsansatzSelected?.label"
              :key="item.label"
            >
              <span>{{ item.label }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <i
            v-if="formVariables.loesungsansatzSelected"
            class="fas fa-times text-danger ml-auto"
            @click="resetLoesungsansatz"
            style="cursor: pointer"
          ></i>
        </b-list-group-item>
      </b-list-group>
      <!-- Akzeptanzgrund -->
      <b-list-group horizontal v-if="formVariables.statusSelected?.enum === 'Akzeptiert'"
        ><b-list-group-item
          class="col-6 d-flex align-items-center"
          style="
            background-color: rgba(114, 114, 114, 0.05);
            border-style: solid;
            border-width: 1px;
            border-color: rgba(114, 114, 114, 0.15);
          "
        >
          <h6 class="m-0 my-1">Akzeptanzgrund</h6>
        </b-list-group-item>
        <b-list-group-item class="col-6 d-flex align-items-center py-0 pl-2"
          ><b-dropdown
            class="dropdown"
            :text="formVariables.akzeptanzgrundSelected?.label || 'Akzeptanzgrund auswählen'"
            size="sm"
            :variant="
              formVariables.statusSelected?.enum === 'Akzeptiert' &&
              formVariables.akzeptanzgrundSelected === null
                ? 'danger'
                : 'success'
            "
          >
            <b-dropdown-item
              v-for="item in akzeptanzgrundOptions"
              @click="changeAkzeptanzgrund(item)"
              :active="item === formVariables.akzeptanzgrundSelected?.label"
              :key="item.label"
            >
              <span>{{ item.label }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <i
            v-if="formVariables.akzeptanzgrundSelected"
            class="fas fa-times text-danger ml-auto"
            @click="resetAkzeptanzgrund"
            style="cursor: pointer"
          ></i>
        </b-list-group-item>
      </b-list-group>
    </b-overlay>

    <template #modal-footer="{ hide }">
      <b-button class="mr-auto" size="sm" @click="openModalWithData" v-b-modal.modalAufgabeEdit
        >Aufgabe erstellen</b-button
      >
      <b-button
        class="mr-4"
        :disabled="disableSichernButton"
        :variant="disableSichernButton ? 'secondary' : 'success'"
        @click="bulkUpdateRows(hide)"
        >Sichern</b-button
      >
      <b-button @click="hide">Abbrechen</b-button>
    </template>
  </b-modal>
</template>

<script>
import apiService from '@/core/common/services/api.service';
import {
  ursachenPossibilities,
  allUrsachen,
  akzeptanzgrundPossibilities,
  allAkzeptanzgruende,
} from '@/core/flugverfuegbarkeit/bearbeitungs-options.config.js';

export default {
  props: {
    ids: { type: Array, required: true },
    stati: { type: Array, required: true },
  },
  data() {
    return {
      isBusy: false,
      formVariables: {
        ursacheSelected: null,
        loesungsansatzSelected: null,
        akzeptanzgrundSelected: null,
        beschreibungText: null,
        statusSelected: null,
      },
      ursachenOptions: [],
      statusOptions: [
        { label: 'Verfügbarkeit geschaffen', enumValue: 1, enum: 'VerfuegbarkeitGeschaffen' },
        { label: 'Verfügbarkeit nicht geschaffen', enumValue: 2, enum: 'VerfuegbarkeitNichtGeschaffen' },
        { label: 'Akzeptiert', enumValue: 3, enum: 'Akzeptiert' },
      ],
      loesungsansatzOptions: [
        { label: 'Gruppe geöffnet', enumValue: 1, enum: 'GruppeGeoeffnet' },
        { label: 'VK erhöht', enumValue: 2, enum: 'VKErhoeht' },
        { label: 'Transferslotanpassung', enumValue: 3, enum: 'Transferslotanpassung' },
        { label: 'Published Fares aktiviert', enumValue: 4, enum: 'PublishedFaresAktiviert' },
        { label: 'Restriktionsanpassung', enumValue: 5, enum: 'Restriktionsanpassung' },
        { label: 'Terminschließung', enumValue: 6, enum: 'Terminschließung' },
      ],
      akzeptanzgrundOptions: [],
    };
  },
  computed: {
    disableSichernButton() {
      const akzeptiertStatusAndAkzeptanzgrundNotSelected =
        this.formVariables?.statusSelected?.enum === 'Akzeptiert' &&
        this.formVariables?.akzeptanzgrundSelected === null;

      const noStatus = this.formVariables.statusSelected === null;

      return noStatus || akzeptiertStatusAndAkzeptanzgrundNotSelected || this.isBusy;
    },
  },
  methods: {
    openModalWithData() {
      this.$emit(
        'openAufgabeErstellenModal',
        this.ids.map((id, i) => ({ id: i, typ: 'Flugverfügbarkeit', artifactId: id, artifactText: null }))
      );
      this.$bvModal.show('modalAufgabeEdit');
    },
    onShow() {
      const rowsDistinctStatus = [...new Set(this.stati)];
      const isOnlyOneStatus = rowsDistinctStatus.length === 1;
      if (isOnlyOneStatus) {
        this.ursachenOptions = ursachenPossibilities.find(item =>
          rowsDistinctStatus.includes(item.status)
        ).options;
        this.akzeptanzgrundOptions = akzeptanzgrundPossibilities.find(item =>
          rowsDistinctStatus.includes(item.status)
        ).options;
      } else {
        this.ursachenOptions = allUrsachen;
        this.akzeptanzgrundOptions = allAkzeptanzgruende;
      }
    },
    changeUrsache(ursache) {
      this.formVariables.ursacheSelected = ursache;
    },
    changeLoesungsansatz(loesungsansatz) {
      this.formVariables.loesungsansatzSelected = loesungsansatz;
    },
    changeStatus(status) {
      this.formVariables.statusSelected = status;
      this.formVariables.akzeptanzgrundSelected = null;
      this.formVariables.loesungsansatzSelected = null;
    },
    changeAkzeptanzgrund(akzeptanzgrund) {
      this.formVariables.akzeptanzgrundSelected = akzeptanzgrund;
    },
    resetFormVariables() {
      this.formVariables = {
        ursacheSelected: null,
        loesungsansatzSelected: null,
        akzeptanzgrundSelected: null,
        beschreibungText: null,
        statusSelected: null,
      };
    },
    async bulkUpdateRows(hide) {
      const ursachen = this.formVariables.ursacheSelected?.enum || null;
      const bearbeitungStatus = this.formVariables.statusSelected?.enum || null;
      const loesungsansatz = this.formVariables.loesungsansatzSelected?.enum || null;
      const akzeptanzgrund = this.formVariables.akzeptanzgrundSelected?.enum || null;
      this.isChangingBearbeitungsData = true;

      const idLength = this.ids.length;

      const params = {
        ids: this.ids,
        ursachen,
        beschreibung: this.formVariables.beschreibungText,
        bearbeitungStatus,
        loesungsansatz,
        akzeptanzgrund,
      };

      if (bearbeitungStatus !== null || (bearbeitungStatus === 'Akzeptiert' && akzeptanzgrund !== null)) {
        this.isBusy = true;
        await apiService
          .put('flugverfuegbarkeit/bulkUpdateStatusData', params)
          .then(response => {
            console.log(response);
            this.toast(idLength + ' ABFHT wurden erfolgreich bearbeitet.');
            this.$emit('bulkUpdateSuccess');
          })
          .catch(error => {
            console.error(error);
          });
        this.isBusy = false;
      }
      hide();
    },
    resetUrsache() {
      this.formVariables.ursacheSelected = null;
    },
    resetLoesungsansatz() {
      this.formVariables.loesungsansatzSelected = null;
    },
    resetAkzeptanzgrund() {
      this.formVariables.akzeptanzgrundSelected = null;
    },
    resetBeschreibung() {
      this.formVariables.beschreibungText = null;
    },
    resetBearbeitungstatus() {
      this.formVariables.statusSelected = null;
      this.formVariables.akzeptanzgrundSelected = null;
      this.formVariables.loesungsansatzSelected = null;
    },
  },
};
</script>
<style></style>
